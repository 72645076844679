import React, { Component } from 'react'

export default class Parallax2 extends Component {
    render() {
        return (
            <div>
                <section className="section background-grey" style={{height: '300px'}}>
                    <div className="overlay">
                        <div className="overlay-wrapper parallax-wrapper">
                            <div className="overlay-inner parallax-background" style={{backgroundImage: 'url("images/background/business-district-roads.jpg")'}} />
                            <div className="overlay-inner background-dark-4 opacity-10" />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}