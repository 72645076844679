import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (

      <footer className="site-footer footer-content-light footer-mobile-content-dark" data-section="footer">
        <div className="container-fluid">
          <p className="copyright xs-text-center text-right">© 2022 Gaytan & Leevan, LLP</p>
        </div>
      </footer>
    )
  }
}
