import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section id="about" className="section bg-white" data-section="about">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center">About</h2>
                <div className="divider divider-alt divider-center divider-dark" />
                <p className="lead mb-50 text-center">All you need to know about our CPA firm.</p>
                <h3>The Firm</h3>
                <p>Gaytan & Leevan, LLP is a privately held company located in Los Angeles, California. We offer an array of tax and accounting services ideal for individuals to medium-sized corporations and everyone in between. Some of our specialties include real estate, professional businesses (e.g., law, medicine), entertainment, estate planning, startup companies, and nonprofit organizations.</p>

                <h3>Raymond Gaytan</h3>
                <p>Raymond Gaytan, CPA, Managing Partner of Gaytan & Leevan, LLP, CPA’s has 38 years of experience in the accounting industry. His areas of expertise include real estate, light, and general manufacturing, retail, entertainment, estate planning, and taxation. Ray advanced from a member of the audit staff of Pannell Kerr Forster, a large national accounting firm, to partner-in-charge of its auditing department in Los Angeles by 1990. In addition to setting policies for the conduct and execution of the firm’s many financial audits, his duties included budgeting and arranging to finance for the firm’s Los Angeles office. In 1990, Ray acquired an interest in Rosenthal & Company, a local firm in which service to closely held family businesses comprised most of the practice. Ray’s clients were, and still are, families and their businesses. Most are also high net worth individuals. Ray has assisted them in everything from estate planning to the arrangement of bank loans and the acquisition of new businesses. In 1994, Ray established his own full-service CPA firm, which is the core of his current practice at Gaytan & Leevan, LLP. In addition to the above-mentioned industry concentrations, Ray has extensive experience in financial audits, client representation in tax audits, and a broad array of management advisory services for his large clientele of closely held family businesses.</p>

                <h3>Edward J. Leevan</h3>
                <p>Edward Leevan, CPA, a Partner of Gaytan & Leevan, LLP, CPA’s has 29 years of experience in the accounting industry. His areas of expertise are focused in individual and business taxation, with an emphasis in real estate, retail sales, entertainment, and high net worth individuals. Edward began his career in the Los Angeles tax department of Deloitte and Touche, LLP, where he was exposed to a wide range of industries and their tax issues. In 1994, Edward moved to private industry, becoming the controller and tax manager for a Deloitte & Touche client; a privately owned real estate holding company comprised of several diverse businesses. Some of their business included a commercial pipe company, a fabricating company, and a reverse engineering company specializing in Government contracts. While working in the private industry, Edward garnered a great deal of skill and knowledge in understating and serving clients’ needs. Edward continued developing his tax skills with Anson Garrett and Company, a local CPA firm in West Los Angeles. His client responsibilities quickly grew as he became the primary contact with several high-net-worth individuals and their closely-held businesses. In 2004, the firm was acquired and Edward joined Gaytan & Leevan, LLP as a Partner. Besides serving his clients’ daily needs, Edward took on the responsibility of establishing the practice in its present location and the day-to-day operations of running the firm.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section background-grey">
        <div className="overlay">
          <div className="overlay-wrapper parallax-wrapper">
            <div className="overlay-inner parallax-background" style={{backgroundSize: 'cover', backgroundImage: 'url("images/background/check.jpg")'}} />
            <div className="overlay-inner background-dark-4 opacity-20" />
          </div>
        </div>
        <div className="container text-center">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <h3 className="h2 text-white">We are big fans of communication and love new experiences. Let’s get to know each other!</h3>
              {/* <a href="#contact" className="btn mb-0 btn-shadow load-content">Learn More</a> */}
            </div>
          </div>
        </div>
        </section>
      </div>
    )
  }
}
