import React, { Component } from 'react'

export default class Services extends Component {
  render() {
    return (

      <div>
        <section id="services" className="section bg-white pb-60" data-section="services">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2 className="text-center">Services</h2>
                <div className="divider divider-alt divider-center divider-dark" />
                <p className="lead mb-50 text-center">We provide professional tax services backed by over 35 years of industry experience.</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon text- g-text">
                    <i className="ion-social-usd-outline" />
                  </div>
                  <h3 className="h4">Accounting</h3>
                  <p class="text-left">We encompass a wide range of professional, timely, and cost account services, such as preparation of general ledger and financial statements, payroll preparation, and preparation of (GAAP) financial statements for management use.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon g-text">
                    <i className="ion-ios-filing-outline" />
                  </div>
                  <h3 className="h4">Audits</h3>
                  <p class="text-left">Our firm provides an intensive examination with the highest level of assurance. Audit services include pre-audit services, reviews, and compilations, or customized services, such as agreed-upon procedures/due diligence.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon g-text">
                    <i className="ion-ios-bookmarks-outline" />
                  </div>
                  <h3 className="h4">Bookkeeping</h3>
                  <p class="text-left">Bookkeeping is vital to the financial health of your business. Timely, efficient bookkeeping services are available for organizations of all sizes. Computerized accounts are prepared, saved, and presented for client review on a quarterly, monthly, and/or annual basis.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon g-text">
                    <i className="ion-ios-briefcase-outline" />
                  </div>
                  <h3 className="h4">Business Taxes</h3>
                  <p class="text-left">We prepare and file all types of entities' tax returns, such as C corporations, S Corporations, Limited Liability Companies, Partnerships, Sole Proprietorships as well as other services like financial statements, business and tax planning, and payroll.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon g-text">
                    <i className="ion-man" />
                  </div>
                  <h3 className="h4">Individual Returns</h3>
                  <p class="text-left">Tax returns for individuals including, (1040) Income Tax Preparation with all Schedules, Personal Financial Statements, Multi-State Returns, E-filing for Individual Clients, Deceased Taxpayer Returns, Small Business Schedule C Preparation, and Non-for-Profit Tax Returns.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="icon-box bordered mb-30 text-center">
                  <div className="icon-box-icon g-text">
                    <i className="ion-ios-locked-outline" />
                  </div>
                  <h3 className="h4">Trust & Pension Returns</h3>
                  <p class="text-left">We prepare and file all types of Trust (1041) Tax Returns in addition to Pension and Profit Sharing (5500) Tax Returns.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
