import React, { Component } from 'react'

export default class Contact extends Component {
  render() {
    return (

      <section id="contact" className="section bg-white pt-60" data-section="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2">
              <h2 className="text-center">Contact Us</h2>
              <div className="divider divider-alt divider-center divider-dark" />
              <p className="lead text-center mb-50">Give us a call or drop us an email and we will get back to you soon.</p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-md-offset-1">
              <div className="row">
                <div className="col-md-4 col-sm-4">
                  <div className="icon-box text-center">
                    <div className="box-container">
                      <div className="icon-box-icon g-text">
                        <i className="ion-ios-telephone-outline" />
                      </div>
                      <p>Phone: <a href="tel:3104775252">(310) 477-5252</a>
                      <br />Fax: <a href="fax:3104785535">(310) 478-5535</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="icon-box text-center">
                    <div className="box-container">
                      <div className="icon-box-icon g-text">
                        <i className="ion-ios-email-outline" />
                      </div>
                        <p><a href="mailto:info@gaytanleevancpa.com">info@gaytanleevancpa.com</a></p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-sm-4">
                  <div className="icon-box text-center">
                    <div className="box-container">
                      <div className="icon-box-icon g-text">
                        <i className="ion-ios-location-outline" />
                      </div>
                      <p><a href="https://goo.gl/maps/5otavdvvaoifpYZt8">11400 West Olympic Blvd. 16th Floor
                      <br />Los Angeles, California 90064 USA</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
