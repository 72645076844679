import React, { Component } from 'react'

export default class Parallax extends Component {
    render() {
        return (
            <div>
                <section className="section background-grey" style={{height: '250px'}}>
                    <div className="overlay">
                        <div className="overlay-wrapper parallax-wrapper">
                            <div className="overlay-inner parallax-background" style={{backgroundImage: 'url("images/background/los-angeles-view.jpg")'}} />
                            <div className="overlay-inner background-dark-4 opacity-10" />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}