import React, { Component } from 'react'

export default class Marketing extends Component {
    render () {
        return (
            <div>
                <section id="marketing" className="section bg-white" data-section="marketing">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="text-center">Director of Business Development</h2>
                                <div className="divider divider-alt divider-center divider-dark" />
                                
                                <img src="images/marketing/tamara-berges.jpg" alt="Tamara Berges" class="rounded img-md" />
                                <h3 class="mt-25">Tamara Berges</h3>
                                <p>Tamara Berges, CPA, CFP, MBT has over twenty-five years of tax, business management and accounting experience in the areas of partnership and corporation taxation, high net worth individual tax and financial planning, Family Office Groups, and IRS tax audit representation and negotiations.</p>
                                <p>She has extensive experience in real estate, internet, retail, and various service providing industries.  Tamara works with companies backed by venture capital and private equity groups and assists such clients in providing their investors with their tax reporting documents.  Her work includes significant multi-state income tax filings as well as international experience with respect to flow through entities and individuals.   Tamara’s merger and acquisition work allows her to help businesses tax plan for their sale and optimize the family’s proceeds.  Her family office experience helps her administer multi-generational family wealth to keep the family operating with financial efficiency.</p>
                                <p>Prior to joining Gaytan & Leevan LLP, Tamara’s gained her foundation in providing tax research, planning and compliance filings for her clients as a Senior Manager at Deloitte.  This foundation allowed her to build and run a successful multimillion dollar CPA firm for many years. </p>
                                <p>She is a member of AICPA, Certified Financial Planners Association, and the California Society of CPAs.   As a CPA and a Certified Financial Planner, she shares her knowledge and gives back to the community as an instructor at UCLA Anderson School of Business, teaching accounting and tax classes.</p>
                                <p>Tamara’s education includes a Bachelor of Arts Degree in Business/Economics from UCLA with an emphasis in Accounting and a master’s degree in Taxation from USC.</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}                   